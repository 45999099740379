@use 'sass:map';
@use '@angular/material' as mat;

@import 'colors';
@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  $background: map.get($color-config, background);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .name-text-missed {
    color: mat.get-color-from-palette($warn-palette, 800);
  }

  button:not([disabled]) {
    .call-btn,
    .dlt-btn {
      color: mat.get-color-from-palette($primary-palette, 800);
    }
  }

  app-call-log-list-item {
    &:hover,
    &:focus,
    &:focus-within {
      .call-log-list-item-container {
        background: mat.get-color-from-palette($background, 'hover');
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    button:not([disabled]) {
      .call-log-list-item-container,
      .call-item-container,
      .call-btn,
      .dlt-btn {
        color: mat.get-color-from-palette($primary-palette, '800-contrast');
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
