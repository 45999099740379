/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use './app/components/tab-selector/tab-selector-theme' as tab-selector;
@use './app/components/header/header-theme' as header;
@use './app/components/settings-header/settings-header-theme' as settings-header;
@use './app/components/call-log-list-item/call-log-list-item-theme' as call-log-item;
@use './app/components/call/call-theme' as call;
@use './app/components/back-to-call/back-to-call-theme' as back-to-call;
@use './app/components/call-history/call-history-theme' as call-history;
@use './app/components/empty-screen-item/empty-screen-item' as empty-screen-item;
@use './app/components/exchange-extension/exchange-extension-theme' as exchange-extension;
@use './app/components/exchange-onpremise-extension/exchange-onpremise-extension-theme' as exchange-onpremise-extension;
@use './app/components/jabra-integration/jabra-integration-theme' as jabra-integration;
@use './app/components/push-incoming-call/push-incoming-call-theme' as push-incoming-call;
@use './app/components/second-call/second-call-theme' as second-call;
@use './app/components/avatar-single/avatar-single-theme' as avatar-single;
@use './app/components/edit-log-level/edit-log-level-theme' as edit-log-level;
@use './app/components/plantronics-integration/plantronics-integration-theme' as plantronics-integration;
@use './app/components/about-overlay/about-overlay-theme' as about-overlay;
@use './app/components/state-banner/state-banner-theme' as state-banner;
@use './app/components/manual-message/manual-message-theme' as manual-message;
@use './app/components/agent-status-indication/agent-status-indication-theme' as agent-status-indication-theme;
@use './app/components/whats-new/whats-new-theme' as whats-new-theme;
@use './app/components/help-overlay/help-overlay-theme' as help-overlay-theme;
@use './app/components/photo-booth/photo-booth-theme' as photo-booth-theme;
@use './app/components/edit-profile-picture/edit-profile-picture' as edit-profile-picture-theme;

@import 'colors';
@import 'variables';
@import 'accessibility';
@import 'utils';

// Override the typography in the core CSS.
@include mat.core();
@include mat.all-component-typographies($custom-typography);

// @include mat.strong-focus-indicators();
.focus-visible {
  @include mat.strong-focus-indicators((
    border-style: dotted,
    border-width: 3px,
    border-radius: inherit,
  ));
}

.cdk-high-contrast-active {
  .dial-dtmf-input.dial-dtmf-input,
  .dial-input.dial-input {
    border: 0.1rem solid;
  }

  &.cdk-high-contrast-white-on-black {
    .mat-checkbox-checkmark-path {
      stroke: white !important;
    }
  }

  &.cdk-high-contrast-black-on-white {
    .mat-checkbox-checkmark-path {
      stroke: black !important;
    }
  }

  &:not(.focus-visible) {
    //non-material focus styles
    .dial-dtmf-input.dial-dtmf-input,
    .dial-input.dial-input {
      &:focus-visible {
        /* Draw the focus when :focus-visible is supported */
        outline: 0.1rem auto black;
        outline-offset: 0.1rem;
      }
    }

    app-call-log-list-item:focus-visible {
      .call-log-list-item-container {
        outline: 0.1rem auto black;
        outline-offset: -0.3rem;
      }
    }

    //material overrides
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      outline: 0.1rem auto black;
      outline-offset: 0.1rem;
    }
  }
}

@media (prefers-color-scheme: light) {
  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  @include angular-material-theme($unify-app-theme);

  .focus-visible {
    @include mat.strong-focus-indicators-theme(black);

    //components in light mode that need to have light focus indicator
    app-notifications-snackbar,
    app-header {
      @include mat.strong-focus-indicators-theme(white);
    }

    //non-material focus styles
    .dial-dtmf-input,
    .dial-input {
      &:focus-visible {
        /* Draw the focus when :focus-visible is supported */
        outline: 0.3rem dotted black;
        outline-offset: 0.3rem;
      }
    }

    app-call-log-list-item:focus-visible {
      .call-log-list-item-container {
        outline: 0.3rem dotted black;
        outline-offset: -0.3rem;
      }
    }

    //material overrides
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      outline: 0.3rem dotted black;
      outline-offset: 0.3rem;
    }
  }

  @include about-overlay.theme($unify-app-theme);
  @include avatar-single.theme($unify-app-theme);
  @include back-to-call.theme($unify-app-theme);
  @include call.theme($unify-app-theme);
  @include call-history.theme($unify-app-theme);
  @include call-log-item.theme($unify-app-theme);
  @include edit-log-level.theme($unify-app-theme);
  @include empty-screen-item.theme($unify-app-theme);
  @include exchange-extension.theme($unify-app-theme);
  @include exchange-onpremise-extension.theme($unify-app-theme);
  @include header.theme($unify-app-theme);
  @include jabra-integration.theme($unify-app-theme);
  @include manual-message.theme($unify-app-theme);
  @include plantronics-integration.theme($unify-app-theme);
  @include push-incoming-call.theme($unify-app-theme);
  @include second-call.theme($unify-app-theme);
  @include settings-header.theme($unify-app-theme);
  @include state-banner.theme($unify-app-theme);
  @include tab-selector.theme($unify-app-theme);
  @include agent-status-indication-theme.theme($unify-app-theme);
  @include whats-new-theme.theme($unify-app-theme);
  @include help-overlay-theme.theme($unify-app-theme);
  @include photo-booth-theme.theme($unify-app-theme);
  @include edit-profile-picture-theme.theme($unify-app-theme);

  .mat-green {
    color: $light-mode-green;

    &.mat-fab,
    &.mat-mini-fab {
      color: $light-primary-text;
      background-color: $light-mode-green;
    }
  }

  .mat-grey {
    color: $dark-primary-text;
    background-color: mat.get-color-from-palette($dark-primary, 100);
  }

  .ui-match {
    color: mat.get-color-from-palette($unify-app-accent, 800);
    font-weight: bold;
  }
}

@media (prefers-color-scheme: dark) {
  @include mat.all-component-colors($dark-theme);

  .focus-visible {
    @include mat.strong-focus-indicators-theme(white);

    //components in light mode that need to have light focus indicator
    app-notifications-snackbar {
      @include mat.strong-focus-indicators-theme(black);
    }

    //non-material focus styles
    .dial-dtmf-input,
    .dial-input {
      &:focus-visible {
        /* Draw the focus when :focus-visible is supported */
        outline: 0.3rem dotted white;
        outline-offset: 0.3rem;
      }
    }

    app-call-log-list-item:focus-visible {
      .call-log-list-item-container {
        outline: 0.3rem dotted white;
        outline-offset: -0.3rem;
      }
    }

    //material overrides
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      outline: 0.3rem dotted white;
      outline-offset: 0.3rem;
    }
  }

  //Overrides of material components
  @include mat-slide-toggle-color($dark-slide-toggle-theme);
  //Custom components
  @include about-overlay.color($dark-theme);
  @include avatar-single.color($dark-theme);
  @include back-to-call.color($dark-theme);
  @include call.color($dark-theme);
  @include call-history.color($dark-theme);
  @include call-log-item.color($dark-theme);
  @include edit-log-level.color($dark-theme);
  @include empty-screen-item.color($dark-theme);
  @include exchange-extension.color($dark-theme);
  @include exchange-onpremise-extension.color($dark-theme);
  @include header.color($dark-theme);
  @include jabra-integration.color($dark-theme);
  @include manual-message.color($dark-theme);
  @include plantronics-integration.color($dark-theme);
  @include push-incoming-call.color($dark-theme);
  @include second-call.color($dark-theme);
  @include settings-header.color($dark-theme);
  @include state-banner.color($dark-theme);
  @include tab-selector.color($dark-theme);
  @include agent-status-indication-theme.color($dark-theme);
  @include whats-new-theme.color($dark-theme);
  @include help-overlay-theme.color($dark-theme);
  @include photo-booth-theme.color($dark-theme);
  @include edit-profile-picture-theme.color($dark-theme);

  .mat-green {
    color: $dark-mode-green;

    &.mat-fab,
    &.mat-mini-fab {
      color: $dark-primary-text;
      background-color: $dark-mode-green;
    }
  }

  .mat-grey {
    color: $dark-primary-text;
    background-color: mat.get-color-from-palette($dark-primary, 400);
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $dark-mode-light-text;
  }

  .ui-match {
    color: mat.get-color-from-palette($dark-accent, 800);
    font-weight: bold;
  }
}

.contacts-wrapper .mat-list-base .mat-list-item {
  height: auto;
}

.mat-simple-snackbar span {
  margin: auto;
  text-align: center;
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  overflow: hidden;
  /* stylelint-disable-next-line unit-disallowed-list */
  font-size: 10px; // Starting base to use clean REM values}
}

.root > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

//material prefix
.exchange-url .mat-form-field.mat-form-field-should-float .mat-form-field-infix {
  position: initial !important;
}

.exchange-url .mat-form-field.mat-form-field-should-float .mat-form-field-label-wrapper {
  top: 0;
  left: 1rem;
}

app-tab-container,
app-footer,
app-header {
  display: flex;
  flex-direction: column;
}

app-header {
  flex-basis: $header-height;

  .user-wrapper {
    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

app-footer {
  flex-basis: 6.5rem;
}

app-tab-container {
  flex: 1;
  height: calc(100% - 10.4rem);
}

app-dialpad,
app-contacts {
  width: $main-view-width;
  text-align: center;
}

app-call-history {
  width: $call-history-list-width;
}

app-contacts {
  display: flex;
  flex: auto;
  flex-direction: column;
  height: 0;
}

app-settings-overlay {
  width: 100%;
}

// class declared in dialog.service.ts
.settings-overlay {
  height: calc(100% - #{$header-height});
  margin-top: $header-height;

  &.with-banner {
    height: calc(100% - (#{$header-height} + #{$banner-height}));
    margin-top: calc(#{$header-height} + #{$banner-height});
  }

  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}

/* ----------scrollbar styling---------- */
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: mat-color($unify-app-primary, 200);

  @media (prefers-color-scheme: dark) {
    background-color: mat.get-color-from-palette($dark-primary, 400);
  }
}

::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background: mat-color($dark-primary, 100);

  @media (prefers-color-scheme: dark) {
    background: mat.get-color-from-palette($dark-primary, 800);
  }
}

/* ----------image-cropper styling---------- */
image-cropper.editor > div > img {
  max-width: $profile-picture-height !important;
  max-height: $profile-picture-height !important;
  border: 0.1rem solid grey;
  pointer-events: none;
}

:root {
  --cropper-outline-color: transparent;
  --cropper-overlay-color: transparent;
}
