
@use 'sass:map';
@use '@angular/material' as mat;

@import 'colors';
@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $warn-palette: map.get($color-config, 'warn');
  $accent-palette: map.get($color-config, 'accent');

  .main-header {
    border-bottom: 0.1rem solid mat.get-color-from-palette($primary-palette, '600-contrast');
  }

  .logout-menu .no-underline,
  .logout-menu .no-underline > .settings-icon {
    color: mat.get-color-from-palette($warn-palette, 800);
  }

  .hunt-group-agent .status {
    border: mat.get-color-from-palette($unify-app-primary, 050) 0 solid;

    &.available {
      border-width: 0.1rem;
      background-color: mat-color($accent-palette, 800);
    }

    &.unavailable {
      border-width: 0.1rem;
      background-color: $light-grey-primary-color;
    }

    &.partiallyUnavailable {
      border-width: 0.1rem;
      background-color: mat-color($warn-palette, 700);
    }
  }

  .icon-container {
    background-color: mat-color($accent-palette, 550);
  }

  .AVAILABLE .mat-icon {
    color: mat-color($accent-palette, 750);
  }

  .BUSY .mat-icon,
  .BUSY_IN_A_CALL .mat-icon,
  .BUSY_IN_AN_EXTERNAL_CALL .mat-icon,
  .DND .mat-icon {
    color: mat-color($warn-palette, 900);
  }

  .AWAY .mat-icon {
    color: mat-color($warn-palette, 700);
  }

  .INVISIBLE .mat-icon,
  .OFFLINE .mat-icon {
    color: mat-color($accent-palette, 600);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
