@use 'sass:map';
@use '@angular/material' as mat;

@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $warn-palette: map.get($color-config, 'warn');
  $accent-palette: map.get($color-config, 'accent');

  .avatar-single {
    .orange {
      border: 0.15rem solid mat.get-color-from-palette($primary-palette, 250); // #91c8e5
      color: $light-primary-text;
      background-color: mat.get-color-from-palette($primary-palette, 950);
    }

    .yellow,
    .avatar-image {
      border: 0.15rem solid mat.get-color-from-palette($primary-palette, 250); // #91c8e5
      color: $light-primary-text;
      background-color: mat.get-color-from-palette($primary-palette, 850); // #0066a1;
    }

    .blue {
      border: 0.15rem solid mat.get-color-from-palette($primary-palette, 250); // #91c8e5
      color: $light-primary-text;
      background-color: mat.get-color-from-palette($primary-palette, 750); // #0e76b2;
    }

    .green {
      border: 0.15rem solid mat.get-color-from-palette($primary-palette, 100);
      color: mat.get-color-from-palette($primary-palette, '250-contrast');
      background-color: mat.get-color-from-palette($primary-palette, 250); // #91c8e5
    }
  }

  .dot {
    background-color: mat-color($accent-palette, 550);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
