@use 'sass:map';
@use '@angular/material' as mat;

@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .dlt-btn {
    color: mat.get-color-from-palette($primary-palette, 800);
  }

  @media (prefers-color-scheme: dark) {
    .dlt-btn {
      color: mat.get-color-from-palette($primary-palette, '800-contrast');
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
