@use 'sass:map';
@use '@angular/material' as mat;

@import 'colors';
@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the accent color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .incoming,
  .outgoing,
  .waiting,
  .failed,
  .busy,
  .declined {
    color: mat.get-color-from-palette($accent-palette, '50-contrast');
    background-color: mat.get-color-from-palette($accent-palette, 50);
  }

  .active-call,
  .active-call-remote {
    color: mat.get-color-from-palette($accent-palette, '100-contrast');
    background-color: mat.get-color-from-palette($accent-palette, 100);
  }

  .call-state-warn {
    color: mat.get-color-from-palette($warn-palette, 800);
  }

  .holding-call {
    color: mat.get-color-from-palette($accent-palette, '200-contrast');
    background-color: mat.get-color-from-palette($accent-palette, 200);
  }

  .call-forwarded {
    color: mat.get-color-from-palette($accent-palette, 400);
  }

  .option-grey {
    color: mat.get-color-from-palette($accent-palette, 600);
  }

  .elapsed-time {
    color: mat.get-color-from-palette($accent-palette, 800);
  }

  .call-state-accent {
    color: mat.get-color-from-palette($accent-palette, 800);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
