
@use 'sass:map';
@use '@angular/material' as mat;

@import 'colors';
@import 'variables';
@import 'primary-palette';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the accent color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .left-pane,
  .right-pane {
    color: mat.get-color-from-palette($primary-palette, 500);

    &:hover,
    &:focus {
      color: mat.get-color-from-palette($primary-palette, 300);
      cursor: pointer;
    }
  }

  .dots {
    .dot {
      border: 0.1rem solid mat.get-color-from-palette($primary-palette, 600);
      background-color: mat.get-color-from-palette($primary-palette, 600);

      &.selected {
        border: 0.1rem solid mat.get-color-from-palette($primary-palette, 300);
        background-color: mat.get-color-from-palette($primary-palette, 300);
      }

      &:hover,
      &:focus {
        border: 0.1rem solid mat.get-color-from-palette($primary-palette, 300);
        background-color: mat.get-color-from-palette($primary-palette, 300);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
