// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use '@angular/material' as mat;
@use 'fontFaces.scss';
@use 'material-icons.scss';

@import '@angular/material/theming';
@import 'accent-palette';
@import 'primary-palette';
@import 'warn-palette';
@import 'unifyphone-svg-icons';

$custom-typography: mat-typography-config(
  $font-family: 'Lato, sans-serif',
  $headline: mat-typography-level(2.4rem, 2.4rem, normal),
  $title: mat-typography-level(2rem, 2.4rem, normal),
  $subheading-2: mat-typography-level(1.6rem, 2.4rem, normal),
  $subheading-1: mat-typography-level(1.4rem, 2.4rem, normal),
  $display-4: mat-typography-level(9.6rem, 11.2rem, 300),
  $display-3: mat-typography-level(6rem, 7.2rem, 300),
  $display-2: mat-typography-level(4.8rem, 5.6rem, normal),
  $display-1: mat-typography-level(3.4rem, 3.6rem, normal),
  $body-2: mat-typography-level(1.6rem, 2.4rem, normal),
  $body-1: mat-typography-level(1.4rem, 2rem, normal),
  $caption: mat-typography-level(1.2rem, 1.6rem, normal),
  $button: mat-typography-level(1.4rem, 1.6rem, normal)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$unify-app-primary: mat.define-palette($custom-blue-palette, 800, 900, 200);
$unify-app-accent: mat.define-palette($custom-accent-palette, 800, 900, 700);
$unify-app-warn: mat.define-palette($custom-red-palette, 800, 800, 800);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$unify-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $unify-app-primary,
      accent: $unify-app-accent,
      warn: $unify-app-warn,
      foreground: $unify-app-warn,
    )
  )
);

$dark-primary: mat.define-palette($custom-dark-mode-blue-palette, 700, 900, 500);
$dark-accent: mat.define-palette($custom-dark-mode-accent-palette, 800, 900, 700);
$dark-warn: mat.define-palette($custom-dark-mode-red-palette, 800, 800, 800);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    )
  )
);

$dark-slide-toggle-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette($custom-dark-mode-blue-palette, A100, 900, 500),
      accent: $dark-accent,
      warn: $dark-warn,
    )
  )
);

//////////////////////////////////////////////////////////////////////////////////////////////

$header-height: 4.2rem;
$banner-height: 2.4rem;
$view-header-height: 6.5rem;
$dial-input-width: 37rem;
$main-view-width: 48.9rem;
$call-history-list-height: calc(100vh - 21.6rem);
$call-history-list-width: 90rem;

// call view containers' height
$call-stage-max-height: 73.2rem;
$call-list-min-height: 11.9rem;
$call-list-max-height: 31.2rem; //(4 * $call-list-item-height)
$call-stage-primary-call: 42rem;
$call-list-item-height: 7.8rem;
$second-call-height: 11.9rem;
$other-person-details-height: 14rem;
$secondary-call-buttons-height: 18.9rem;

//second-call
$btn-min-width: 4.2rem;

// settings
$settings-tab-width: 74rem;

//call-history
$call-log-item-height: 6.2rem;

//profile picture large size
$profile-picture-height: 25rem;
