@use 'sass:map';
@use '@angular/material' as mat;

@import 'colors';
@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the accent color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .partially-unavailable .mat-slide-toggle-bar {
    background-color: mat-color($warn-palette, 700);
  }

  .agent-status-selection-dialog .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: mat-color($accent-palette, 800);
    }

    .mat-slide-toggle-thumb {
      background-color: $light-primary-text;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config  !=null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config  !=null {
    @include typography($theme);
  }
}
