// see http://mcg.mbitson.com

$dark-primary-text: black;
$light-primary-text: white;
$dark-mode-light-text:#e1e1e1;
$dark-grey-primary-color: #00000033;
$light-grey-primary-color: #cccccc;

$custom-blue-palette: (
  050: #e3f2f8,
  100: #baddef,
  200: #91c8e5,
  250: #91c8e5,
  300: #69b3db,
  400: #4aa3d5,
  500: #2b95d0,
  600: #1f88c4,
  650: #0e76b2,
  700: #0e76b2,
  750: #0e76b2,
  800: #0066a1,
  850: #0066a1,
  900: #004a82,
  950: #004a82,
  A100: #a2c7ff,
  A200: #6fa8ff,
  A400: #3c89ff,
  A700: #237aff,
  A800: #1666a1,
  contrast: (
    050: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    250: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    650: $light-primary-text,
    700: $light-primary-text,
    750: $light-primary-text,
    800: $light-primary-text,
    850: $light-primary-text,
    900: $light-primary-text,
    950: $light-primary-text,
    A100 : $dark-primary-text,
    A200 : $dark-primary-text,
    A400 : $light-primary-text,
    A700 : $light-primary-text,
    A800 : $light-primary-text,
  )
);

$custom-dark-mode-blue-palette: (
  050 : #fafafa,
  100 : #f5f5f5,
  200 : #eeeeee,
  250 : #747474,
  300 : #e0e0e0,
  400 : #bdbdbd,
  500 : #9e9e9e,
  600 : #757575,
  650:  #91c8e5,
  700 : #616161,
  750 : #626262,
  800 : #424242,
  850 : #4a4a4a,
  900 : #212121,
  950 : #0d0d0d,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  A800 : #ffffff,
  contrast: (
    050: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    250: $dark-mode-light-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    650: $dark-primary-text,
    700: $dark-mode-light-text,
    750: $dark-mode-light-text,
    800: $dark-mode-light-text,
    850: $dark-mode-light-text,
    900: $dark-mode-light-text,
    950: $dark-mode-light-text,
    A100 : $dark-primary-text,
    A200 : $dark-primary-text,
    A400 : $dark-primary-text,
    A700 : $dark-primary-text,
    A800 : $dark-primary-text,
  )
);
