
@use 'sass:map';
@use '@angular/material' as mat;

@import 'colors';
@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the accent color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $primary-palette: map.get($color-config, 'primary');

  .selector-wrapper {
    color: mat.get-color-from-palette($accent-palette, '50-contrast');
    background-color: mat.get-color-from-palette($accent-palette, 050);

    .active:not(.hide-selection) {
      color: $light-primary-text;
      background-color: mat.get-color-from-palette($primary-palette, default);
    }
  }

  .badge {
    background-color: mat.get-color-from-palette($warn-palette, 800);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
