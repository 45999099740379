@use 'sass:map';
@use '@angular/material' as mat;

@import 'colors';
@import 'variables';

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the accent color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  .call-fwd {
    color: mat.get-color-from-palette($accent-palette, 600);
  }

  .second-call-incoming {
    background-color: mat.get-color-from-palette($accent-palette, 250);
  }

  .second-call-hold {
    background-color: mat.get-color-from-palette($accent-palette, 150);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
